<template>
  <div>
    <b-alert
      v-if="!prev_school"
      variant="info"
      show
    >
      <div class="alert-body">
        <span>{{$t('fields.no_license')}}</span>
      </div>
    </b-alert>
    <b-alert
      v-else
      variant="warning"
      show
    >
    <h4 class="alert-heading">
        ¡Atención!
      </h4>
      <div class="alert-body">
        <span>{{$t('fields.school_modify')}}</span>
      </div>
    </b-alert>

    <validation-observer
      ref="refFormObserver"
    >
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>

          <!-- Field: School -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="School"
              rules="required"
              :custom-messages="{ required: $t('validators.required') }"
            >
              <b-form-group
                :label="$t('fields.school')"
                label-for="school"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.school"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  :selectable="val => val.can_select"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="school"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      type="button"
      @click="submitChanges"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.save_changes') }}
    </b-button>
    <b-button
      variant="outline-secondary"
      type="button"
      @click="hasHistory() 
        ? $router.go(-1) 
        : $router.push({ 
          name: 'school-teachers-view', 
          params: { 
            id: school.id,
            teacher_id: userData.id, 
          } 
        })"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.go_back') }}
    </b-button>
  </div>
</template>

<script>
import { editLicence } from '@/api/routes'

import {
  BAlert, BButton, BMedia, BAvatar, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BAlert,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    school: {
      type: Object,
      required: true
    },
    userData: {
      type: Object,
      required: true,
    },
    schoolOptions: {
      type: Array,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    langsOptions: {
      type: Array,
      required: true,
    },
  },
  data(){
    const now = new Date()
    const tomorrow = new Date(now)
    tomorrow.setDate(tomorrow.getDate() + 1)

    return {
      required,
      alphaNum,
      password,

      minDate: tomorrow,

      prev_school: null

    }
  },
  mounted(){
    this.prev_school = this.userData.school
  },
  methods: {
    hasHistory () { 
       return window.history.length > 2 
    },
    submitChanges(){
      this.$bvModal
          .msgBoxConfirm('Are you sure you want to modify this licence?', {
            title: 'Update licence',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              this.save()
            }
          })
    },
    async save(){
      let data = {
          'tab': 'school',
          'user_id': this.userData.id,
          'school_id':this.userData.school
        }
      await this.$http.post(editLicence, data).then( response => {
        if(response.data.status == 200){
          this.$emit('refetch-data')
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
